import React from 'react';
import Slider from 'react-slick';

const CarrosselMarcas = ({ onMarcaSelecionada }) => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const handleMarcaFilter = (marcaSelecionada) => {
        onMarcaSelecionada(marcaSelecionada); 
    };

    return (
        <div className="carrossel-container">
            <Slider {...settings}>
                {['chevrolet', 'fiat', 'ford', 'citroen', 'toyota', 'nissan', 'hyundai', 'renault', 'volkswagen', 'honda', 'jeep', 'audi', 'bmw', 'bugatti', 'byd', 'caoacherry', 'dodge', 'ferrari', 'lamborghini', 'mercedes', 'mini', 'mitsubishi', 'porsche', 'tesla', 'volvo'].map((marca) => (
                    <div key={marca}>
                        <button className="brand-button" onClick={() => handleMarcaFilter(marca)}>
                            <img src={`./images/logoMarcas/${marca}.png`} alt={marca} className="brand-logo" />
                        </button>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CarrosselMarcas;
