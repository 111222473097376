import React, { Component } from 'react';
import FloatingWhatsApp from './WhatsAppFlutuante';


class Rodape extends Component {
  constructor(props) {
    super(props);

    // Definindo o estado inicial
    this.state = {
      isHovered: false, // Controle do hover
    };
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer-3 footer-topbar light page-section-pt">
        <div className="container">
          <div className="row top">
            <div className="col-lg-3 col-md-12">
              <img
                className="img-fluid"
                id="logo_footer_dark"
                src="images/logo.png"
                alt=""
              />
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="social text-lg-right text-center">
                <ul>
                  <li>
                    <a href={this.props.revenda.facebook_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href={this.props.revenda.instagram_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" target="_blank"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@webmotors" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube" target="_blank"></i></a>
                  </li>
                  <li>
                    <a href="https://x.com/Lmotorsudi" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" target="_blank"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href={'https://api.whatsapp.com/send?text=Olá, tudo bem? Estou com uma dúvida, poderia me ajudar? &phone=55' + retiraMascara(this.props.revenda.petelefone2, "link")}><i className="fa fa-whatsapp"></i></a>
                  </li>
                  {/* <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li> */}
                  <li>
                    <a target="_blank" href={this.props.revenda.mapa_link} className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                  </li>

                  <li>
                    <a href="https://www.tiktok.com/@lmotors.uberlandia" target="_blank" rel="noopener noreferrer">
                      <div className="tikTok">
                        <i className="fa fa-map-tiktok">
                          <img src="/images/svg/tiktok.svg" alt="Tik Tok" />
                        </i>
                      </div>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <div className="row" style={{ display: "flex", justifyContent: "center", textAlign: "center"}}>
            <div className="col-lg-5 col-md-12">
              <div className="about-content">
                <h2 style={{color: '#FFF'}}> L Motors: Loja Multimarcas de Carros Seminovos em Uberlândia</h2>
                <p style={{color: '#FFF'}}>Na <strong>L Motors</strong>, sua loja multimarcas de confiança em Uberlândia, você encontra carros seminovos de qualidade, com ampla variedade de modelos para atender diferentes estilos e necessidades. Somos especialistas em oferecer transparência, atendimento personalizado e condições exclusivas, como financiamento facilitado em até 60x e garantia de motor e câmbio por 90 dias. Com anos de experiência no mercado, garantimos uma experiência de compra segura e satisfatória. Visite nossa loja e descubra o carro ideal para você com a excelência que só a L Motors pode oferecer!</p>
                <p></p> </div>            </div>
            <div className="col-lg-7 col-md-12">
              <h6 className="text-sobrenos">Informações da loja</h6>
              <div className="usefull-link">
                <div className="row">
                  <div className="col-md-4 footer_info">
                    <ul className="text-sobrenos-info">
                      <li>
                        <a target="_blank" href={this.props.revenda.mapa_link} className="wow slideInLeft" rel="noopener noreferrer" >
                          {this.props.revenda.peendereco_fiscal} - {this.props.revenda.peendereco_fiscal_numero} - {this.props.revenda.pebairro_fiscal} - {this.props.revenda.pecidade} - {this.props.revenda.peuf}
                        </a>
                      </li>
                      {/*<li>
                         <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >
                          <i className="fa fa-phone"></i>
                          { retiraMascara(this.props.revenda.petelefone1) }
                        </a>
                      </li>*/}
                      {/* <li>
                      <a target="_blank" href="https://linktr.ee/">
                        <i className="fa fa-whatsapp"></i>
                        WHATSAPPs
                        </a>
                      </li> */}
                      <li>
                        <a target="_blank" href={'https://api.whatsapp.com/send?text=Olá, tudo bem? Estou com uma dúvida, poderia me ajudar?&phone=55' + retiraMascara(this.props.revenda.petelefone2, "link")}>
                          <i className="fa fa-whatsapp"></i>
                          {retiraMascara(this.props.revenda.petelefone2)}
                        </a>
                      </li>
                      {/*<li>
                        <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }>
                          <i className="fa fa-whatsapp"></i>
                          { retiraMascara(this.props.revenda.petelefone3) }
                          </a>
                      </li>*/}
                      <li>
                        <i className="fa fa-envelope-o" style={{ marginRight: "10px" }}> </i>
                        <a href={"mailto:" + this.props.revenda.peemail}>{this.props.revenda.peemail}</a>
                      </li>
                      <li>
                        <i className="fa fa-clock-o"></i> {this.props.revenda.horario_seg_sex} <br></br> {this.props.revenda.horario_sab}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p style={{ textAlign: "center" }}>
                    © Copyright 2024 L Motors | Todos os direitos reservados.
                    <a href="https://intermedioveiculos.com.br/" target="HKe2fZ02jqcU0ixfKIxz"
                    > Intermédio Veículos Marketing Digital</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;