import React, { useState } from "react";

const FloatingWhatsApp = () => {
  const [isHovered, setIsHovered] = useState(false); // Estado para hover

  return (
    <div>
      {/* Link para WhatsApp */}
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        className="whatsappFlutuante"
        href="https://api.whatsapp.com/send?text=Olá, tudo bem? Estou com uma dúvida, poderia me ajudar?&phone=5534996576000"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          zIndex: 1000,
        }}
      >
        <img
          src="/images/botao-whatsapp.png"
          alt="WhatsApp"
          style={{
            width: isHovered ? "250px" : "245px", // Efeito de hover
            height: isHovered ? "110px" : "100px",
            marginBottom: "100px",
            transition: "all 0.3s ease",
          }}
          onMouseEnter={() => setIsHovered(true)} // Ativar hover
          onMouseLeave={() => setIsHovered(false)} // Desativar hover
        />
      </a>
    </div>
  );
};

export default FloatingWhatsApp;
